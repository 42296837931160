import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";

import { NavLink, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import BetCard1 from "./BetCard1";
import BetCard2 from "./BetCard2";
import Header from "../Components/Header";

export default function Homepage({ walletUpdate }) {
  const history = useHistory();
  function urlMatches(game_type) {
    const validPaths = [
      "classiclite",
      "classicpro",
      "classicextreme",
      "oneToken",
      "twoToken",
      "threeToken",
    ];
    return validPaths.some((path) => path === game_type);
  }
  useEffect(() => {
    if (!urlMatches(game_type)) {
      history.push("/");
    }
  });
  let userID = useRef();

  const isMounted = useRef(true);
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const wssurl = "wss://socketv2.rajasthaniludo.com";
  const nodeMode = process.env.NODE_ENV;
  let baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  /// user details start

  const [user, setUser] = useState();
  const [userid, setUserid] = useState();
  const [socket, setSocket] = useState();

  const [userAllData, setUserAllData] = useState();

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data._id);
        setUserAllData(res.data);
        setUserid(res.data._id);
        userID.current = res.data._id;
        setMount(true);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };
  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );
  const [Game_Ammount, setGame_Ammount] = useState(0);

  const [allgame, setallgame] = useState([]);
  const [mount, setMount] = useState(false);
  const [runningGames, setRunningGames] = useState();
  const [ownRunning, setOwnRunning] = useState([]);
  const [amounts, setAmount] = useState([]);
  useEffect(() => {
    //    if()
    WebSocket.prototype.emit = function (event, data) {
      if (this.readyState === WebSocket.OPEN)
        this.send(JSON.stringify({ event, data }));
    };
    WebSocket.prototype.listen = function (eventName, callback) {
      this._socketListeners = this._socketListeners || {};
      this._socketListeners[eventName] = callback;
    };
    let access_token = localStorage.getItem("token");

    let socketUrl;
    if (nodeMode === "development") {
      socketUrl = wssurl + "/server?t=" + access_token;
    } else {
      socketUrl = wssurl + "/server?t=" + access_token;
    }

    let socket = new WebSocket(socketUrl);

    function openFunc() {
      socket.onopen = () => {
        // socket.emit("webgamerun");
        setTimeout(() => {
          socket.emit("webgamerun", { userid: userID.current });
        }, 500);

        setInterval(() => {
          //   socket.emit("gameCreated");
          //   socket.emit("challengeOngoing");
          socket.emit("webgamerun", { userid: userID.current });
        }, 5000);
      };
      setSocket(socket);
    }

    function listenFunc() {
      socket.onmessage = function (e) {
        try {
          const { event, data } = JSON.parse(e.data);
          if (socket._socketListeners[event]) {
            socket._socketListeners[event](data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      socket.listen("ping", (data) => {
        socket.emit("pong", 2);
        clearTimeout(socket.pingTimeout);
        socket.pingTimeout = setTimeout(() => {
          console.log("ping terminate works 🏩");
          socket.close();
          setSocket(undefined);
        }, 30000 + 1000);
      });

      socket.listen("webgameAcepptor", (data) => {
        let owenedRunning = [];
        //        console.log(data);
        if (data.status != 400) {
          data.forEach((element) => {
            if (
              element.Created_by._id == userID.current ||
              element.Accepetd_By._id == userID.current
            ) {
              if (game_type == element.Game_type) {
                if (element.Status == "new" || element.Status == "running") {
                  owenedRunning.push(element);
                }
              }
            }
          });
          setOwnRunning(owenedRunning);
          walletUpdate();
        } else {
          if (data.userid == userID.current) {
            Swal.fire({
              title: data.msg,
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
      });
      socket.listen("webgamerunning", (data) => {
        let owenedRunning = [];
        if (data.status != 400) {
          data.forEach((element) => {
            if (
              (element.Created_by != null &&
                element.Created_by._id == userID.current) ||
              (element.Accepetd_By != null &&
                element.Accepetd_By._id == userID.current)
            ) {
              if (game_type == element.Game_type) {
                if (
                  element.Status != "drop" ||
                  element.Status != "cancelled" ||
                  element.Status != "completed"
                ) {
                  owenedRunning.push(element);
                }
              }
            }
          });
          setOwnRunning(owenedRunning);
        } else {
          if (data.userid == userID.current) {
            Swal.fire({
              title: data.msg,
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
      });
      socket.listen("webgamerunningupdate", (data) => {
        let owenedRunning = [];
        if (data.status != 400) {
          data.forEach((element) => {
            if (
              element.Created_by == userID.current ||
              element.Accepetd_By == userID.current
            ) {
              if (game_type == element.Game_type) {
                if (
                  element.Status != "drop" ||
                  element.Status != "cancelled" ||
                  element.Status != "completed"
                ) {
                  owenedRunning.push(element);
                }
              }
            }
          });
          setOwnRunning(owenedRunning);
        } else {
          if (data.userid == userID.current) {
            Swal.fire({
              title: data.msg,
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
      });
      socket.listen("webgamejoin", (data) => {
        //        let owenedRunning = [];
        // data.forEach((element) => {
        if (
          data.Created_by == userID.current ||
          data.Accepetd_By == userID.current
        ) {
          if (game_type == data.Game_type) {
            history.push("/viewgame1/" + data._id);
          }
        }
        // });
        //s  setOwnRunning(owenedRunning);
      });
    }
    function closeFunc() {
      socket.onclose = () => {
        console.log("socket disconnected wow 😡");
      };
    }
    openFunc();
    listenFunc();
    closeFunc();
    const interval = setInterval(() => {
      //      openFunc();
      //      console.log("Function called every 5 seconds");
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    const amounts = generateRandomValuesInRange(exampleObject);
    setAmount(amounts);
    let access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {
        //  history.push("/login")
      }, 500);
    }
    role();
  }, [mount]);
  const deletegame = (id) => {
    socket.emit("deleteWebGame", { id: id });
  };
  const AcceptChallang2 = (amount) => {
    //    console.log("click here");
    socket.emit("webgames", {
      user_id: userID.current,
      Game_Ammount: amount,
      Game_type: game_type,
    });
  };
  function generateRandomValuesInRange(obj) {
    const randomValuesInRange = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const range = obj[key];
        const randomValue = Math.floor(
          Math.random() * (range[1] - range[0] + 1) + range[0]
        );
        randomValuesInRange[key] = randomValue;
      }
    }

    return randomValuesInRange;
  } // Example object
  const exampleObject = {
    100: [250, 300],
    150: [150, 200],
    200: [120, 150],
    250: [100, 150],
    300: [100, 120],
    350: [70, 100],
    400: [50, 70],
    450: [20, 50],
    500: [20, 50],
  };

  // console.log(randomValuesDescending);

  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer" style={{ minHeight: "100vh" }}>
        <div
          className={css.mainArea}
          style={{ paddingTop: "60px", minHeight: "100vh" }}
        >
          <div className="px-4 py-3">
            <div className="mb-3">
              <img
                src={process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"}
                alt=""
                width="20px"
              />
              <span
                style={{ color: "#000" }}
                className={`ml-2 ${css.gamesSectionTitle}`}
              >
                Open Battles
              </span>
              <span
                className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold`}
                style={{ right: "1.5rem", color: "#fff" }}
              >
                Rules
                <NavLink to="/Rules">
                  <img
                    className="ml-2"
                    src={process.env.PUBLIC_URL + "/Images/Homepage/info.png"}
                    alt=""
                  />
                </NavLink>
              </span>
            </div>
            {ownRunning.length != 0 &&
              ownRunning.map(
                (allgame, key) =>
                  allgame._id && (
                    <BetCard2
                      isdelete={true}
                      key={key}
                      deletegame={deletegame}
                      allgame={allgame}
                      user={user}
                    />
                  )
              )}
          </div>
          <div className={css.dividerX}></div>
          <div className="px-4 py-3">
            <div className="mb-2">
              <img
                src={process.env.PUBLIC_URL + "/Images/Homepage/battleIcon.png"}
                alt=""
                width="20px"
              />
              <span
                style={{ color: "#000" }}
                className={`ml-2 ${css.gamesSectionTitle}`}
              >
                Running Battles
              </span>
            </div>

            {amounts &&
              Object.entries(amounts).map(([key, value]) => (
                <BetCard1
                  key={key}
                  playing={value}
                  AcceptChallang={AcceptChallang2}
                  amount={key}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
