import { Link } from "react-router-dom";

import React, { useState, useEffect } from "react";
import css from "../css/gamehis.module.css";
import ReactPaginate from "react-paginate";
import Rightcontainer from "../Components/Rightcontainer";
import axios from "axios";

const Gamehistory = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [user, setUser] = useState(null);
  const [game, setGame] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [isActive, setIsActive] = useState(0);

  const limit = 50;

  const role = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    try {
      const res = await axios.get(`${baseUrl}me`, { headers });
      setUser(res.data);
      Allgames(res.data._id, pageNumber, limit);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handlePageClick = (data) => {
    const currentPage = data.selected;
    setPageNumber(currentPage);
  };

  const Allgames = async (id, page, limit) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`
    };
    try {
      const res = await axios.get(`${baseUrl}game/history/user/${id}?page=${page}&_limit=${limit}`, { headers });
      const gameHis = res.data.data.filter((ele) => 
        ele.Status === 'completed' || ele.Status === 'cancelled' || ele.Status === 'conflict'
      );
      setGame(gameHis);
      setNumberOfPages(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching game history:", error);
    }
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString('default', { month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
  };

  useEffect(() => {
    if (user && user._id) {
      Allgames(user._id, pageNumber, limit);
    } else {
      role();
    }
  }, [pageNumber]);

  return (
    <div>
      <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>
        <div className="pt-5">
          <div className="mt-4">
              <Link 
      to={"/transaction-history"} 
      style={{
        padding: '10px 20px',
        borderRadius: '20px',
        margin: '0 10px',
        textDecoration: 'none',
        color: isActive ? 'white' : '#333',
        backgroundColor: isActive ? '#007bff' : 'transparent',
        border: isActive ? 'none' : '1px solid #ddd',
        display: 'inline-block',
        textAlign: 'center',
      }}
    >
Payment
    </Link>          
    <Link 
      to={"/Gamehistory"} 
      style={{
        padding: '10px 20px',
        borderRadius: '20px',
        margin: '0 10px',
        textDecoration: 'none',
        color: isActive ? 'white' : '#333',
        backgroundColor:'#007bff' ,
        border: isActive ? 'none' : '1px solid #ddd',
        display: 'inline-block',
        textAlign: 'center',
      }}
    >
<span style={{color:"#fff"}}>Game</span>
    </Link>              <Link 
      to={"/Referral-history"} 
      style={{
        padding: '10px 20px',
        borderRadius: '20px',
        margin: '0 10px',
        textDecoration: 'none',
        color: isActive ? 'white' : '#333',
        backgroundColor: isActive ? '#007bff' : 'transparent',
        border: isActive ? 'none' : '1px solid #ddd',
        display: 'inline-block',
        textAlign: 'center',
      }}
    >
Refferal
    </Link>
          </div>
        </div>
        {game.length > 0 ? (
          game.map((card) => (
            <div key={card._id} className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}>
              <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                <div>{dateFormat(card.createdAt).split(',')[0]}</div>
                <small>{dateFormat(card.createdAt).split(',')[1]}</small>
              </div>
              <div className={`${css.list_divider_y}`} />
              <div className={`mx-3 d-flex ${css.list_body}`}>
                <div className="d-flex align-items-center">
                  <picture className="mr-2">
                    <img
                      height="32px"
                      width="32px"
                      src={process.env.PUBLIC_URL + '/Images/LandingPage_img/ludo.jpeg'}
                      alt=""
                      style={{ borderRadius: "5px" }}
                    />
                  </picture>
                </div>
                <div className="d-flex flex-column font-8">
                  <div>
                    {card.Status === "completed" 
                      ? (card.Winner && user && card.Winner._id === user._id 
                        ? 'Win Against ' 
                        : 'Lost Against ') 
                      : card.Status} 
                    <b> {card.Accepetd_By && user && card.Accepetd_By._id === user._id ? card.Created_by.Name : card.Accepetd_By?.Name}</b>.
                  </div>    
                  <div className={`${css.games_section_headline}`}>
                    Roomcode: {card.Room_code?card.Room_code:""}
                  </div> 
                </div>
              </div>
              {card.Status === 'completed' && (
                <div style={{ marginLeft: 'auto' }} className="right-0 d-flex align-items-end pr-3 flex-column">
                  <div className="d-flex float-right font-8">
                    <div className="text-danger">{card.losestatus}</div>
                    <div className="text-success">{card.winstatus}</div>
                    <picture className="ml-1 mb-1">
                      <img height="21px" width="21px" src={process.env.PUBLIC_URL + '/Images/LandingPage_img/global-rupeeIcon.png'} alt="" />
                    </picture>
                    <span className="pl-1">
                      {card.Status === "completed" 
                        ? (card.Winner && user && card.Winner._id === user._id 
                          ? `(+) ${card.winnAmount}` 
                          : `(-) ${card.Game_Ammount}`) 
                        : ''}
                    </span>
                  </div>
                  <div className={`${css.games_section_headline}`} style={{ fontSize: "0.6em", whiteSpace: 'nowrap' }}>
                    closing balance :{card.Winner && user && card.Winner._id === user._id ? card.Winner_closingbalance : card.Loser_closingbalance}
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center">
            <img
              src={process.env.PUBLIC_URL + '/Images/nogamehistory.png'}
              alt="no data"
              width={300}
              height={300}
              className="img-fluid"
              style={{ marginTop: "25%" }}
            />
            <div className="mt-2">
              <h3 className="font-weight-bold">No Game History</h3>
              <p> You have no game history yet.</p>
            </div>
          </div>
        )}
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
      {numberOfPages > 1 && (
        <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          pageCount={numberOfPages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      )}
    </div>
  );
};

export default Gamehistory;
